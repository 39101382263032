import React from 'react';
import styled, { css } from 'styled-components';
import { Header } from '@marty-js/sdk/src/layout/menu/header';
import { PromotionalBanner } from '@marty-js/sdk/src/layout/menu/promotional-banner';
import { useSkinContext } from '@marty-js/design/src/utils/skin';
import { getImageUrl } from '@marty-js/design/src/utils/image.service';
import { useSdkConfig } from '@marty-js/sdk/src/utils/config';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { Footer } from '../footer/footer';

const Viewport = (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;

const SiteContainer = styled.div`
  display: flex;
`;

const ContentArea = styled.div`
  flex: 1;
  margin: auto;
  max-width: 1200px;
`;

const GenecyDFPAdWallpaperCont = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 200px;
  margin: 0 auto;
  background-color: transparent;
  position: relative;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const SkinTopWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 200px;
  overflow: hidden;
  position: relative;
`;

const SkinTop = styled.div`
  display: block;
  width: 100%;
  height: 200px;
  position: fixed;
`;

const SkinLeft = styled.div`
  display: block;
  width: 300px;
  height: 9800px;
  overflow: hidden;
  position: absolute;
  top: 200px;
  left: -300px;
`;

const SkinRight = styled.div`
  display: block;
  width: 300px;
  height: 9800px;
  overflow: hidden;
  position: absolute;
  top: 200px;
  right: -300px;
`;

interface Props {
  data: {
    menu: {};
    underMenu: {};
    footer: {};
    promotionalBanner: {};
  };
}

type PromotionalBannerProps = {
  title: string;
  shortTitle: string;
  url: string;
};

const sanitizeBannerComponent = (component: any): PromotionalBannerProps => {
  if (!component || !component?.data) {
    return null;
  }

  const title = component?.data?.overrides?.title || component?.data?.item?.item?.title;
  const shortTitle = component?.data?.overrides?.shortTitle || component?.data?.item?.item?.title;
  const url = component?.data?.overrides?.url || component?.data?.item?.item?.url;

  if (title && shortTitle && url) {
    return {
      title,
      shortTitle,
      url,
    };
  }

  return null;
};

export const DefaultLayout: React.FC<Props> = ({ children, data }) => {
  const promotionalBanner: PromotionalBannerProps = sanitizeBannerComponent(data?.promotionalBanner);

  const { skin } = useSkinContext();
  const sdkConfig = useSdkConfig();

  const imageTop = skin?.top
    ? getImageUrl({
        hostname: sdkConfig.imageApiHostname,
        imageId: skin?.top,
        webp: false,
        width: 1200,
        height: 200,
      })
    : null;

  const imageLeft = skin?.left
    ? getImageUrl({
        hostname: sdkConfig.imageApiHostname,
        imageId: skin?.left,
        webp: false,
        width: 300,
        height: 9800,
      })
    : null;

  const imageRight = skin?.right
    ? getImageUrl({
        hostname: sdkConfig.imageApiHostname,
        imageId: skin?.right,
        webp: false,
        width: 300,
        height: 9800,
      })
    : null;

  return (
    <Viewport style={{ background: skin?.color }}>
      <Header headerComponent={data?.menu} underHeaderComponent={data?.underMenu} />
      {promotionalBanner && (
        <PromotionalBanner
          title={promotionalBanner.title}
          shortTitle={promotionalBanner.shortTitle}
          url={promotionalBanner.url}
        />
      )}
      <div id="ctBoc">
        {skin?.top && (
          <GenecyDFPAdWallpaperCont>
            <SkinTopWrapper>
              <SkinTop style={{ background: `url(${imageTop}) 0px 0px no-repeat` }} />
            </SkinTopWrapper>
            {skin?.left && <SkinLeft style={{ background: `url(${imageLeft}) 0px 0px no-repeat` }} />}
            {skin?.right && <SkinRight style={{ background: `url(${imageRight}) 0px 0px no-repeat` }} />}
          </GenecyDFPAdWallpaperCont>
        )}
        <SiteContainer>
          <ContentArea className="content-wrapper">{children}</ContentArea>
        </SiteContainer>
        <Footer footerComponent={data?.footer} />
      </div>
    </Viewport>
  );
};
