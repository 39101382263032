import React from 'react';
import { LogoProps } from './types';

function Linkedin({ width, height, color }: LogoProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 22" fill="none">
      <title>LinkedIn</title>
      <path
        d="M20.376 0H1.62403C1.19331 0 0.780231 0.171102 0.475667 0.475667C0.171102 0.780231 0 1.19331 0 1.62403L0 20.376C0 20.8067 0.171102 21.2198 0.475667 21.5243C0.780231 21.8289 1.19331 22 1.62403 22H20.376C20.8067 22 21.2198 21.8289 21.5243 21.5243C21.8289 21.2198 22 20.8067 22 20.376V1.62403C22 1.19331 21.8289 0.780231 21.5243 0.475667C21.2198 0.171102 20.8067 0 20.376 0ZM6.55722 18.7428H3.24958V8.23472H6.55722V18.7428ZM4.90417 6.77875C4.65378 6.78367 4.40494 6.73828 4.17241 6.64528C3.93988 6.55228 3.72838 6.41356 3.55044 6.23733C3.3725 6.0611 3.23174 5.85094 3.1365 5.61932C3.04126 5.3877 2.99347 5.13932 2.99597 4.88889C2.99141 4.63705 3.03766 4.38687 3.13194 4.1533C3.22622 3.91972 3.36661 3.70755 3.54472 3.52944C3.72283 3.35133 3.935 3.21094 4.16857 3.11666C4.40215 3.02238 4.65232 2.97614 4.90417 2.98069C5.15588 2.97634 5.40589 3.02274 5.63929 3.11712C5.87268 3.21149 6.08467 3.35191 6.26262 3.53C6.44056 3.70809 6.58081 3.92019 6.675 4.15366C6.76919 4.38713 6.81538 4.63718 6.81083 4.88889C6.81333 5.13918 6.76559 5.38744 6.67044 5.61896C6.5753 5.85048 6.43467 6.06056 6.2569 6.23677C6.07912 6.41298 5.8678 6.55174 5.63545 6.64483C5.4031 6.73792 5.15443 6.78346 4.90417 6.77875ZM18.7504 18.7504H15.4428V13.0121C15.4428 11.3178 14.7232 10.7953 13.7958 10.7953C12.8135 10.7953 11.851 11.5347 11.851 13.0533V18.7504H8.54486V8.25H11.7257V9.70597H11.7639C12.0878 9.05208 13.2046 7.94444 14.9111 7.94444C16.7567 7.94444 18.7504 9.03986 18.7504 12.2482V18.7504Z"
        fill={color}
      />
    </svg>
  );
}

export default Linkedin;
