import Head from 'next/head';
import React from 'react';

type Props = {
  microData: (string | null)[] | null;
};

let serverSideMicroData: (string | null)[] | null = null;
if (typeof window !== 'undefined') {
  const microdataElements = document.querySelectorAll('head [data-type="microdata"]');
  serverSideMicroData = Array.from(microdataElements).map((elem) => elem.textContent);
}

const MicroData = ({ microData }: Props) => {
  const validMicroData = microData ?? serverSideMicroData;

  if (validMicroData) {
    return (
      <Head>
        {validMicroData?.filter(Boolean).map((data, index) => (
          <script
            type="application/ld+json"
            key={index}
            data-testid={`microdata-${index}`}
            data-type="microdata"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        ))}
      </Head>
    );
  }

  return null;
};

export default MicroData;
