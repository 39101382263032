import React from 'react';
import { LogoProps } from './types';

function Facebook({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
      <title>Facebook</title>
      <path
        d="M15.3309 3.81896H17.4306V0.161958C17.0684 0.112125 15.8226 0 14.3716 0C11.3443 0 9.27044 1.90421 9.27044 5.40404V8.625H5.92969V12.7132H9.27044V23H13.3664V12.7142H16.572L17.0809 8.62596H13.3654V5.80942C13.3664 4.62779 13.6845 3.81896 15.3309 3.81896Z"
        fill={color}
      />
    </svg>
  );
}

export default Facebook;
