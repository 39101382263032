import React, { useEffect, useMemo, useRef, useState } from 'react';
import Head from 'next/head';
import { useAdContext } from './AdContext';
import consoleDebug from '../utils/consoleDebug';
import { useStorageDarkMode } from '../utils/storageDarkMode';

declare global {
  interface Window {
    optidigitalQueue: {
      cmd: Function[];
    };
    optidigitalToolbox: {
      setPageTargeting: Function;
      spaManualLoad: Function;
    };
  }
}

type Props = { pageContentLoaded: boolean };

const Script = React.memo(
  ({ adConfig, site }: { adConfig: string; site: string }) => {
    const id = 'optidigital-ad-init';

    return (
      <Head>
        <script
          type="text/javascript"
          async
          dangerouslySetInnerHTML={{
            __html: `
            var currentThemeAd = window.localStorage.getItem('preferMode') ?? (window?.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
            var adConfig = '${adConfig}';
            var updatedJsonString = adConfig.replace(/"mode":"[^"]+"/, \`"mode":"\${currentThemeAd}"\`);
            var optidigitalQueue = optidigitalQueue || {};
            optidigitalQueue.cmd = optidigitalQueue.cmd || [];
            var i='${id}';
            if (!document.getElementById(i)) {
              var opti=document.createElement('script');
              opti.async=true;
              opti.id=i;
              opti.type='text/javascript';
              opti.setAttribute("config",updatedJsonString);
              opti.src='//scripts.opti-digital.com/tags/?site=${site}';
              var node=document.getElementsByTagName('head')[0];
              node.parentNode.insertBefore(opti, node);
            } 
          `,
          }}
        />
      </Head>
    );
  },
  () => {
    return true;
  },
);

const AdScript = ({ pageContentLoaded }: Props) => {
  const adContext = useAdContext();
  const { site, adUnit } = adContext;
  const isScript = useRef<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const darkMode = useStorageDarkMode();
  if (!isScript.current) {
    adContext.setReloadScript = setReload;
  }

  const adConfig = useMemo(() => {
    if (!isScript.current && reload) {
      setReload(false);
    }

    return {
      adUnit,
      pageTargeting: {
        kw: adContext.tags.map((kw) => kw.replace("'", ' ')),
        env: adContext.env,
        mode: darkMode ? 'dark' : 'light',
        issafe: adContext.isSafe ? 1 : 0,
      },
    };
  }, [adUnit, adContext.tags, adContext.isSafe, darkMode, adContext.env, setReload, reload]);

  const [jsonPrevContext, setJsonPrevContext] = useState(JSON.stringify(adConfig));

  useEffect(() => {
    isScript.current = true;
    const jsonContext = JSON.stringify(adConfig);
    if (pageContentLoaded && isScript.current && jsonPrevContext !== jsonContext) {
      setJsonPrevContext(jsonContext);
      consoleDebug({ msg: 'Push pub', adConfig });
      window.optidigitalQueue?.cmd.push(() => {
        window.optidigitalToolbox?.setPageTargeting('kw', adConfig.pageTargeting.kw);
        window.optidigitalToolbox?.setPageTargeting('mode', adConfig.pageTargeting.mode);
        window.optidigitalToolbox?.spaManualLoad(adConfig.adUnit);
        document.querySelectorAll('.ad-content').forEach((element) => {
          while (element.firstChild) {
            element.removeChild(element.firstChild);
          }
          const dataSlot = element.getAttribute('data-slot');
          const newDiv = document.createElement('div');
          newDiv.className = dataSlot;
          newDiv.style.display = 'none';

          element.appendChild(newDiv);
        });
      });
    }
  }, [adConfig, pageContentLoaded, adConfig.adUnit, isScript, jsonPrevContext]);

  if (adContext.tags.includes('nopub')) {
    return null;
  }

  return <Script adConfig={jsonPrevContext} site={site} />;
};

export default AdScript;
