import React from 'react';
import { LogoProps } from './types';

function ClubicText({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 399 105" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M171.094 75.1358C171.094 85.4352 161.89 89.9757 156.109 89.9757C149.121 89.9757 141.125 84.7211 141.125 75.1358V26.409H122.547C122.547 26.409 122.687 57.1314 122.687 75.1358C122.687 92.4599 137.144 104.827 152.657 104.827C163.839 104.827 171.094 98.5437 171.094 98.5437V103.529H189.672V26.409H171.094V75.1358ZM40.6213 86.4312C27.8359 86.4312 17.6359 75.4669 19.0863 62.5159C20.1601 52.918 27.8334 45.0629 37.4903 43.7247C43.4958 42.8921 49.1131 44.4994 53.477 47.6903C56.8526 50.1578 62.1329 50.1924 65.3757 47.5576L73.0318 41.3333C65.3523 31.0237 52.779 24.5096 38.7076 25.1541C18.4808 26.0823 1.86294 42.139 0.470076 62.1419C-1.15087 85.4349 17.4472 104.828 40.6213 104.828C52.6112 104.828 63.3761 99.6324 70.7497 91.3946L64.7665 84.584C61.6824 81.0748 56.4502 80.3387 52.5278 82.9003C49.1119 85.1317 45.0202 86.4312 40.6213 86.4312Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.51 0.0844727H315.088V13.9689H296.51V0.0844727ZM296.51 26.4081H315.088V103.529H296.51V26.4081Z"
        fill={color}
      />
      <path
        d="M368.444 86.4313C356.087 86.4313 346.146 76.1917 346.801 63.814C347.362 53.2222 355.947 44.4442 366.621 43.5806C371.713 43.1679 376.472 44.5011 380.358 47.0394C384.387 49.6718 389.731 49.066 392.9 45.4603L398.777 38.7707C391.101 30.0622 379.664 24.6888 366.974 25.1375C346.572 25.8568 329.697 41.9742 328.292 62.142C326.67 85.4335 345.27 104.828 368.444 104.828C380.546 104.828 391.398 99.5373 398.777 91.1667L392.897 84.4744C389.765 80.9113 384.445 80.1907 380.479 82.8178C377.037 85.1009 372.897 86.4313 368.444 86.4313ZM243.599 88.5049C232.141 88.5049 222.851 77.9673 222.851 64.9684C222.851 51.9698 232.141 41.4318 243.599 41.4318C255.058 41.4318 264.346 51.9698 264.346 64.9684C264.346 77.9673 255.058 88.5049 243.599 88.5049ZM246.762 25.1109C238.839 25.1109 231.505 27.8466 225.511 32.4966V0.0854492H206.934V103.529H225.511V97.4414C231.505 102.092 238.839 104.827 246.762 104.827C266.999 104.827 283.404 86.9811 283.404 64.9684C283.404 42.9556 266.999 25.1109 246.762 25.1109Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.4944 0.0844727H105.072V103.529H86.4944V0.0844727Z"
        fill={color}
      />
    </svg>
  );
}

export default ClubicText;
