import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import dynamic from 'next/dynamic';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { useSsoPayload } from '@marty-js/api-sdk/services/sso';
import { useSdkConfig } from '../../utils/config';

const UserIcon = dynamic(() => import('@marty-js/design/src/icons/user'));

const AccountButtonHref = styled.a`
  display: none;
  cursor: pointer;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
    `,
  )}
`;

const MobileAccountHref = styled.a`
  display: flex;
  margin-left: 16px;
  cursor: pointer;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const AccountButton = styled.span`
  display: none;
  cursor: pointer;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
    `,
  )}
`;

const MobileAccountButton = styled.span`
  display: flex;
  margin-left: 16px;
  cursor: pointer;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const HeaderProfile = (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;

const AvatarImg = styled.img`
  border-radius: 25px;
`;

interface Props {
  icon?: string;
}

export function UserProfile({ icon }: React.PropsWithChildren<Props>) {
  const t = useTranslation();
  const [ssoPayload] = useSsoPayload();
  const sdkConfig = useSdkConfig();

  const [urlSso, setUrlSso] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      const redirect = `${sdkConfig.baseUrl}/login`;
      const referer = document.referrer;
      const payload = await ssoPayload({
        variables: {
          referer,
          return_sso_url: redirect,
        },
      });
      const url = `${sdkConfig.ssoUrl}api/external_apps/connect/clubic?sso=${payload?.data?.sso.sso}&sig=${payload?.data?.sso.sig}`;
      localStorage.setItem('ssoUrl', url);

      setUrlSso(url);
    };

    fetchData();
  }, [sdkConfig.baseUrl, sdkConfig.ssoUrl, ssoPayload]);

  const checkPayload = async () => {
    const width = 600;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    localStorage.setItem('urlBeforeLogin', document.documentURI);
    window.open(urlSso, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== sdkConfig.baseUrl) {
        return;
      }

      if (event.data.type === 'userLoggedIn') {
        window.location.reload();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [sdkConfig.baseUrl]);

  return (
    <HeaderProfile>
      {icon ? (
        <>
          <AccountButtonHref href="https://forum.clubic.com/latest" title={t('header.account')}>
            <AvatarImg src={`https://forum.clubic.com/${icon}`} height={24} alt="username" />
          </AccountButtonHref>
          <MobileAccountHref href="https://forum.clubic.com/latest">
            <AvatarImg src={`https://forum.clubic.com/${icon}`} height={24} alt="username" />
          </MobileAccountHref>
        </>
      ) : (
        <>
          <AccountButton onClick={() => checkPayload()} title={t('header.account')}>
            <UserIcon width={24} height={24} color="var(--text-color)" />
          </AccountButton>
          <MobileAccountButton onClick={() => checkPayload()}>
            <UserIcon width={24} height={24} color="var(--text-color)" />
          </MobileAccountButton>
        </>
      )}
    </HeaderProfile>
  );
}
