import React from 'react';
import { LogoProps } from './types';

function Twitter({ width, height, color }: LogoProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 22 21" fill="none">
      <title>X</title>
      <path
        d="M0.0535329 0L8.54748 11.357L0 20.5908H1.92385L9.40732 12.5063L15.4535 20.5908H22L13.028 8.5951L20.984 0H19.0601L12.1685 7.44545L6.6 0H0.0535329ZM2.88263 1.41695H5.89005L19.1705 19.1739H16.1631L2.88263 1.41695Z"
        fill={color}
      />
    </svg>
  );
}

export default Twitter;
