import React from 'react';
import type { LogoPropsOptionalColor } from './types';

function ArrowDown({ width, height, color }: LogoPropsOptionalColor) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 12"
      fill={color ? 'none' : null}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.0002 0.400024L10.0002 7.40002L17.0002 0.400024L19.8002 1.80002L10.0002 11.6L0.200195 1.80002L3.0002 0.400024Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowDown;
