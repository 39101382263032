import React from 'react';
import { LogoProps } from './types';

function Youtube({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <title>Youtube</title>
      <path
        d="M34.162 9.56453C33.7731 8.11866 32.6331 6.9789 31.1874 6.58957C28.5464 5.86694 17.982 5.86694 17.982 5.86694C17.982 5.86694 7.41804 5.86694 4.77699 6.56217C3.35914 6.95109 2.19136 8.11887 1.80244 9.56453C1.10742 12.2054 1.10742 17.6821 1.10742 17.6821C1.10742 17.6821 1.10742 23.1865 1.80244 25.7997C2.19177 27.2454 3.33133 28.3851 4.7772 28.7745C7.44585 29.4973 17.9824 29.4973 17.9824 29.4973C17.9824 29.4973 28.5464 29.4973 31.1874 28.8021C32.6333 28.4129 33.7731 27.2732 34.1624 25.8275C34.8572 23.1865 34.8572 17.7099 34.8572 17.7099C34.8572 17.7099 34.885 12.2054 34.162 9.56453ZM14.6185 22.7417V12.6225L23.4033 17.6821L14.6185 22.7417Z"
        fill={color}
      />
    </svg>
  );
}

export default Youtube;
