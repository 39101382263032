export type HeaderLinkLevel2 = {
  title: string;
  url?: string;
  iconSlug: string;
  order: number;
};

export type HeaderLinkLevel1 = {
  title: string;
  url?: string;
  iconSlug?: string;
  order: number;
  emphasize: boolean;
  columnSeparator?: boolean;
  subLinks?: HeaderLinkLevel2[][];
};

export const formatNavLinksFromComponent = (navComponent: any) => {
  const links = navComponent?.data?.linksExtended?.data;
  const formattedLinks = [];
  if (links) {
    for (let j = 0; j < links.length; j += 1) {
      const link = links[j];
      const modelLink: HeaderLinkLevel1 = {
        order: link.order,
        title: link.title,
        emphasize: link?.emphasize,
        url: link.url,
        iconSlug: link.iconSlug,
        columnSeparator: link.columnSeparator,
      };

      if (link?.links?.length) {
        const columns = [];
        let currentColumn = [];
        for (let i = 0; i < link.links.length; i += 1) {
          const subLink = link.links[i];

          const modelSubLink: HeaderLinkLevel2 = {
            order: subLink.order,
            title: subLink.title,
            url: subLink.url,
            iconSlug: subLink.iconSlug,
          };

          if (subLink.columnSeparator) {
            columns.push(currentColumn);
            currentColumn = [];
          }

          currentColumn.push(modelSubLink);
        }

        columns.push(currentColumn);

        modelLink.subLinks = columns;
      }

      formattedLinks.push(modelLink);
    }
  }

  return formattedLinks;
};
